exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-contentful-about-us-page-template-url-tsx": () => import("./../../../src/pages/{contentfulAboutUsPageTemplate.url}.tsx" /* webpackChunkName: "component---src-pages-contentful-about-us-page-template-url-tsx" */),
  "component---src-pages-contentful-generic-page-template-url-tsx": () => import("./../../../src/pages/{contentfulGenericPageTemplate.url}.tsx" /* webpackChunkName: "component---src-pages-contentful-generic-page-template-url-tsx" */),
  "component---src-pages-contentful-people-page-template-url-tsx": () => import("./../../../src/pages/{contentfulPeoplePageTemplate.url}.tsx" /* webpackChunkName: "component---src-pages-contentful-people-page-template-url-tsx" */),
  "component---src-pages-contentful-press-release-page-template-url-tsx": () => import("./../../../src/pages/{contentfulPressReleasePageTemplate.url}.tsx" /* webpackChunkName: "component---src-pages-contentful-press-release-page-template-url-tsx" */),
  "component---src-pages-contentful-simple-rich-text-page-template-url-tsx": () => import("./../../../src/pages/{contentfulSimpleRichTextPageTemplate.url}.tsx" /* webpackChunkName: "component---src-pages-contentful-simple-rich-text-page-template-url-tsx" */),
  "component---src-pages-contentful-text-sections-page-template-url-tsx": () => import("./../../../src/pages/{contentfulTextSectionsPageTemplate.url}.tsx" /* webpackChunkName: "component---src-pages-contentful-text-sections-page-template-url-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-office-tsx": () => import("./../../../src/pages/press-office.tsx" /* webpackChunkName: "component---src-pages-press-office-tsx" */)
}

